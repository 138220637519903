<template>
  <div>
    <s-dialog
      v-model:visible="showBatchActive"
      class="cart-batch__modal"
      :type="'full'"
    >
      <template #title>
        <div
          class="cart-list__header"
          style="border-bottom: none"
        >
          <div class="cart-list__header-title">
            <h4>
              {{ `${language.SHEIN_KEY_PC_22381} (${cartRowsNum})` }}
            </h4>
          </div>
          <a
            ref="closeBtn"
            v-enterkey
            class="cart-batch__closeBtn"
            tabindex="0"
            role="button"
            :aria-label="language.SHEIN_KEY_PC_14807"
            @click="handleClose()"
          >
            <Icon
              name="sui_icon_closed_14px_1"
              size="20px"
            />
          </a>
        </div>
      </template>

      <div 
        v-infinite-scroll="handleInfiniteScroll"
        class="new-cart-batch"
        infinite-scroll-disabled="disabledScroll"
        infinite-scroll-nodata="noMoreData"
        infinite-scroll-distance="6000"
      >
        <template
          v-for="mallCartItem in mallCartList"
          :key="mallCartItem.mall_code"
        >
          <template v-for="shop in mallCartItem.shops">
            <template v-if="shop.store_code">
              <store-header
                :key="mallCartItem.mall_code+'-'+shop.store_code + '-' + shop.shopName"
                :store-code="shop.store_code"
                :shop-name="shop.shopName"
                :store-trend-logo="shop.store_trend_logo"
                :store-type="shop.store_type"
                :has-store-coupon="shop?.hasStoreCoupon == '1'"
                :preferred-seller-store="shop.preferred_seller_store"
                :store-routing="shop.storeRouting"
                :mall-code="mallCartItem.mall_code"
                :is-batch-active-page="true"
                :is-shein-store="shop.isSheinStore"
              >
                <s-checkbox
                  :label="mallCartItem.mall_code +','+shop.store_code"
                  :model-value="isChecked(shop.cart_id_list)"
                  gap="0px"
                  outside-check
                  @change="(res) => handleGroupCheckedItem(res, shop)"
                />
              </store-header>
              <StoreShipping
                v-if="shop?.shippingActivityData"
                :key="mallCartItem.mall_code+'-'+shop.store_code + '-' + shop.shopName"
                :shipping-activity-data="shop.shippingActivityData"
                :mall-code="mallCartItem.mall_code"
              />
            </template>
            <template
              v-for="(content, index1) in shop.contentData"
              :key="index1"
            >
              <promotion-header
                v-if="content.groupHeadInfo && content.groupHeadInfo.data"
                :key="'promotion-'+content.groupHeadInfo.data.promotion_id"
                :promotion-info="content.groupHeadInfo"
                :is-batch-active-page="true"
              >
                <s-checkbox
                  v-if="content.groupHeadInfo.data.is_shop_group != 1 && ![4, 22].includes(+content.groupHeadInfo.data.type_id)"
                  :label="content.groupHeadInfo.data.promotion_id"
                  :model-value="isChecked(content.groupHeadInfo.data.cart_id_list)"
                  gap="0px"
                  outside-check
                  @change="(res) => handleGroupCheckedItem(res, content.groupHeadInfo.data)"
                />
              </promotion-header>
              <CartItem
                v-for="(item, index) in content.productLineInfoList.filter(i => renderCount > cartIds.indexOf(i.id))"
                :key="item.id"
                :item="item"
                :index="index"
                :is-batch-active-page="true"
                class="cart-batch__item"
              >
                <s-checkbox-group
                  v-model="batchEditCartItemIds"
                  :disabled="item.isNewcomerItem"
                  gap="0px"
                  @click="handleBatchItemClick(item)"
                >
                  <s-checkbox
                    :label="item.id"
                    :disabled="item.isNewcomerItem"
                    gap="0px"
                    @change="handleBatchItem"
                  />
                </s-checkbox-group>
              </CartItem>
              <!-- 按店铺或促销组维度商品列表后方添加padding, 8px, 无法通过仅css实现 -->
              <div
                class="place-holder"
                style="padding-bottom: 8px;background: #fff;"
              ></div>
            </template>
          </template>
        </template>
            
        <template v-if="soldoutItems.length">
          <SoldOutHeader :is-batch-active-page="true" />
          <CartItem
            v-for="(item, index) in soldoutItems.filter(i => renderCount > cartIds.indexOf(i.id))"
            :key="item.id"
            :item="item"
            :index="index"
            :is-batch-active-page="true"
            class="cart-batch__item"
          >
            <s-checkbox-group
              v-model="batchEditCartItemIds"
              gap="0px"
            >
              <s-checkbox
                :label="item.id"
                gap="0px"
                @change="handleBatchItem"
              />
            </s-checkbox-group>
          </CartItem>
        </template>
      </div>

      <template #footer>
        <div
          class="cart-batch__footer"
        >
          <div>
            <s-checkbox
              ref="checkedAll"
              v-enterkey="{ handler: () => $refs.checkedAll.click() }"
              :model-value="checkAll"
              gap="24px"
              tabindex="0"
              role="checkbox"
              :aria-label="language.SHEIN_KEY_PC_15813"
              :aria-checked="checkAll"
              @change="handleAllChecked"
            >
              {{ language.SHEIN_KEY_PC_15813 }}
            </s-checkbox>
            <span
              class="cart-batch__selectedNum"
              tabindex="0"
            >{{ `${batchEditCartItemIds.length} ${language.SHEIN_KEY_PC_15659}` }}</span>
          </div>
          <span class="cart-batch__operation">
            <!-- Move to wishlist -->
            <s-button
              v-enterkey="{ handler: preHandleBatchM2W }"
              v-tap="{id: '1-7-7-8'}"
              :type="['H36PX']"
              class="cart-batch__operation-item"
              :disabled="batchEditCartItemIds.length == 0"
              :tabindex="batchEditCartItemIds.length == 0 ? -1 : 0"
              role="button"
              @click="preHandleBatchM2W"
            >
              {{ language.SHEIN_KEY_PC_14552 }}
            </s-button>
            <!-- Delete -->
            <s-button
              v-enterkey="{ handler: preHandleBatchDetete }"
              v-tap="{id: '1-7-7-3'}"
              :type="['H36PX']"
              class="cart-batch__operation-item"
              :disabled="batchEditCartItemIds.length == 0"
              :tabindex="batchEditCartItemIds.length == 0 ? -1 : 0"
              role="button"
              @click="preHandleBatchDetete"
            >
              {{ language.SHEIN_KEY_PC_14548 }}
            </s-button>
          </span>
        </div>
      </template>
    </s-dialog>
  </div>
</template>
<script>
export default {
  name: 'CartBatchDialog',
}
</script>
<script setup>
import PromotionHeader from '../cartList/PromotionHeader.vue'
import StoreHeader from '../cartList/StoreHeader.vue'
import SoldOutHeader from '../cartList/SoldOutHeader.vue'
import CartItem from 'public/src/pages/cart_v2/components/functional/cartList/CartItem.vue'
import { SMessage } from '@shein-aidc/sui-message'
import { useStore } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import SimilarPopover from 'public/src/pages/common/similar_products'
import { Icon } from '@shein-aidc/icon-vue3'
import { computed, ref, onMounted, watch } from 'vue'
import { template } from '@shein/common-function'
import { useCartDeleteDialog } from 'public/src/pages/cart_v2/components/functional/cart-delete-dialog/useCartDeleteDialog'
import StoreShipping from 'public/src/pages/cart_v2/components/functional/cartList/StoreShipping.vue'
import MallShpping from 'public/src/pages/cart_v2/components/functional/cartList/MallShipping.vue'

const { showCartDeleteDailog } = useCartDeleteDialog()

const { state, commit, dispatch } = useStore()

const loaded = ref(false)
const renderCount = ref(6)

const language = computed(() => state.language)

daEventCenter.addSubscriber({ modulecode: '1-7-7' })

const batchActive = computed(() => state.batchActive)
const loginStatus = computed(() => state.loginStatus)
const mallCartList = computed(() => state.mallCartList)
const showBatchActive = computed(() => loaded.value && batchActive.value)
const batchEditItemIds = computed(() => state.batchEditItemIds)
const cartIds = computed(() => state.cartIds)
const soldoutItems = computed(() => state.soldoutItems)
const cartRowsNum = computed(() => state.cartInfo.allProductLineSumQuantity)
const batchDeleteStayPopUp = computed(() => state.batchDeleteStayPopUp)
const carts = computed(() => state.carts)
const noMoreData = computed(() => renderCount.value && renderCount.value >= cartRowsNum.value)
const hasNewcomerItem = computed(() => carts.value.some(item => item.isNewcomerItem))
const checkAll = computed(() => {
  const cartsLength = carts.value.filter(f => !f.isNewcomerItem).length
  const batchedItemsLength = batchEditCartItemIds.value.length
  return cartsLength == batchedItemsLength
})
const cartItemMap = computed(() => state.cartItemMap)

const batchEditCartItemIds = computed( {
  get () {
    return batchEditItemIds.value
  },
  set (arr) {
    commit('updateState', {
      key: 'batchEditItemIds',
      value: arr || []
    })
  }
})

watch(() => checkAll.value, n => {
  if (n) {
    analysis_operator('click_selectallproduct')
  }
}, { immediate: true })

watch(()=> showBatchActive.value, n => {
  if (n) {
    analysis_operator('expose_batchedit')
  }
}, { immediate: true })

const handleNewcomerItemTips = () => {
  SMessage({
    type: 'info',
    message: language.value.SHEIN_KEY_PC_27125,
  })
  daEventCenter.triggerNotice({
    daId: '1-7-3-39',
  })
}

const handleBatchItem = ({ checked = false } = {}) => {
  if (checked) {
    daEventCenter.triggerNotice({
      daId: '1-7-3-7',
    })
  }
}

const isChecked = (goodsIdList = []) => {
  return goodsIdList.filter(id => !cartItemMap.value?.[id]?.isNewcomerItem && !batchEditCartItemIds.value.includes(id)).length ? false : true
}

const handleClose = () => {
  commit('handleBatchActive', {})
  dispatch('clearFilter')
  renderCount.value = 6
}

const handleGroupCheckedItem = (res, item) => {
  let is_checked = isChecked(item.cart_id_list)
  item.cart_id_list.forEach(id => {
    if (!is_checked) {
      if (!batchEditCartItemIds.value.includes(id) && !(cartItemMap.value?.[id]?.isNewcomerItem)) {
        batchEditCartItemIds.value.push(id)
      }
    } else {
      let site = batchEditCartItemIds.value.indexOf(id)
      site > -1 && (batchEditCartItemIds.value.splice(site, 1))
    }
  })
  if (!is_checked && hasNewcomerItem.value && item.cart_id_list.find(id => cartItemMap.value?.[id]?.isNewcomerItem)) {
    handleNewcomerItemTips()
  }
}
const handleAllChecked = ({ checked = false }) => {
  if (checkAll.value) {
    commit('updateState', {
      key: 'batchEditItemIds',
      value: []
    })
  } else {
    if (hasNewcomerItem.value) {
      handleNewcomerItemTips()
    }
    const itemIds = carts.value.filter(f => !f.isNewcomerItem).map(item => item.id)
    commit('updateState', {
      key: 'batchEditItemIds',
      value: itemIds || []
    })
  }
}

const handleBatchItemClick = item => {
  if (item.isNewcomerItem) {
    handleNewcomerItemTips()
  }
}

const handleShowSimilarModal = (product, config) => {
  SimilarPopover.showModal(product, config)
}

const handleInfiniteScroll = () =>{
  renderCount.value += 6
  if(noMoreData.value) return
  requestAnimationFrame(() => {
    renderCount.value += 6
  })
}

function analysis_operator (type, payload) {
  switch (type) {
    case 'expose_popup_deletefail':
      daEventCenter.triggerNotice({
        daId: '1-7-7-1',
      })
      break
    case 'expose_popup_wishlistfail':
      daEventCenter.triggerNotice({
        daId: '1-7-7-2',
      })
      break
    case 'click_deleteproductsno':
      daEventCenter.triggerNotice({
        daId: '1-7-7-4',
      })
      break
    case 'click_deleteproductsyes':
      daEventCenter.triggerNotice({
        daId: '1-7-7-5',
        extraData: {
          carts: payload.carts, batchIds: payload.ids, result: payload.result
        }
      })
      break
    case 'expose_popup_itemdeleted':
      daEventCenter.triggerNotice({
        daId: '1-7-7-6',
        extraData: {
          carts: payload.carts, batchIds: payload.ids
        }
      })
      break
    case 'expose_popup_itemwishlist':
      daEventCenter.triggerNotice({
        daId: '1-7-7-7',
        extraData: {
          carts: payload.carts, batchIds: payload.ids
        }
      })
      break
    case 'expose_batchedit':
      daEventCenter.triggerNotice({
        daId: '1-7-7-10',
      })
      break
    case 'click_selectallproduct':
      daEventCenter.triggerNotice({
        daId: '1-7-7-11',
      })
      break
    case 'click_movewishlistyes':
      daEventCenter.triggerNotice({
        daId: '1-7-7-12',
        extraData: {
          carts: payload.carts, batchIds: payload.ids, result: payload.result
        }
      })
      break
    case 'click_movewishlistno':
      daEventCenter.triggerNotice({
        daId: '1-7-7-13',
      })
      break
    default:
      break
  }
}

const preHandleBatchM2W = () => {
  if (batchEditItemIds.value.length == 0) return
  commit('updateState', { key: 'modalOptions', value: {
    show: true,
    titleText: language.value.SHEIN_KEY_PC_17955,
    okCallback: async () => {
      if (loginStatus.value) {
        handleBatchM2W()
      } else {
        let loginBeforeCheckedGoodsid = carts.value.filter(item => batchEditItemIds.value.includes(item.id)).map(item => (item.mall_code + '-' + item.store_code + '-' + item.skuCode))
        const { result, res } = await dispatch('handleCartLogin', { type: 'wishlist' })
        if (res.code == 0) {
          if (carts.value.length) {
            let itemIds = carts.value.filter(item => loginBeforeCheckedGoodsid.includes(item.mall_code + '-' + item.store_code + '-' + item.skuCode)).map(item => item.id)
            commit('updateState', {
              key: 'batchEditItemIds',
              value: itemIds || []
            })
          }
          if (result.equalCart) {
            handleBatchM2W()
          } else {
            SMessage({
              offset: 136,
              type: 'info',
              message: language.value.SHEIN_KEY_PC_17957,
              duration: 5000
            })
          }
        }

      }
    },
  }
  })
}

const handleBatchM2W = () => {
  commit('updateState', {
    key: 'loadingShow',
    value: true
  })

  const reqBatchIds = batchEditItemIds.value
  const reqCarts = [].concat(carts.value)

  dispatch('fetchCartBatchWish', { params: { id: reqBatchIds } })
    .then(async res => {
      commit('updateState', {
        key: 'loadingShow',
        value: false
      })
      if (res.code == '0') {
        handleClose()
        resetBatchEditItemIds()
        analysis_operator('expose_popup_itemwishlist', { carts: reqCarts, ids: reqBatchIds })
        analysis_operator('click_movewishlistyes', { carts: reqCarts, ids: reqBatchIds, result: 1 })
      } else {
        if (res.code == -4 || res.code == -2) { // 触发容灾
          SMessage({
            offset: 136,
            type: 'error',
            message: language.value.SHEIN_KEY_PC_17753,
          })
        } else if (Number(res.code) === 400427 && res.info){ // 24小时内收藏数超过限制
          SMessage({
            offset: 136,
            type: 'info',
            message: template(res.info.limit, language.value.SHEIN_KEY_PC_19347),
          })
        } else {
          SMessage({
            offset: 136,
            type: 'error',
            message: language.value.SHEIN_KEY_PC_17737,
          })
        }

        analysis_operator('expose_popup_wishlistfail')
        analysis_operator('click_movewishlistyes', { carts: reqCarts, ids: reqBatchIds, result: 0 })
          
      }
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log(err)
      commit('updateState', {
        key: 'loadingShow',
        value: false
      })
    })
}

const resetBatchEditItemIds = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'  // 可选，平滑滚动到顶部
  })
}

const preHandleBatchDetete = () => {
  if (batchEditItemIds.value.length == 0) return
  showCartDeleteDailog({
    type: 'batch',
    data: batchDeleteStayPopUp.value,
    ids: batchEditItemIds.value,
    language: language.value,
  })
}

onMounted(() => {
  loaded.value = true
  commit('updateState', { key: 'loadingShow', value: false })
})
</script>
<style lang="less">
.cart-batch-img{
  padding-left: 24px;
}
.cart-batch__selectedNum{
  font-size: 16px;
  color: #999;
  position: relative;
  bottom: -2px;
}
.cart-batch__modal .sui-dialog__wrapper .sui-dialog__title {
  /* stylelint-disable-next-line selector-max-specificity */
  .cart-list__header {
    padding: 12px 16px;
  }
}
</style>

<style lang="less" scoped>
.new-cart-batch {
  margin-bottom: 20px;
  .batch-checkbox{
    line-height: unset;
    display: flex;
    align-items: center;
  }
  /* stylelint-disable-next-line selector-max-specificity */
  :deep(.cart_item-header.promo-group) {
    background: #FFF6F3;
    padding: 12px 16px;
    margin-top: 1px;
    /* stylelint-disable-next-line selector-max-specificity */
    section {
      padding: 2px 0;
    }
  }
  /* stylelint-disable-next-line selector-max-specificity */
  :deep(.cart_item-header.not-promoGoods) {
    padding: 0 16px;
    margin-top: 1px;
  }
  .common-group-header {
    padding: 0px 16px;
    height: 1px;
    background: #fff;
  }
  .place-holder + .common-group-header {
    position: relative;
    &:before {
      border-bottom: 1px dashed #e5e5e5;
      content: "";
      width: calc(100% - 32px);
      display: block;
      top: 0;
      position: absolute;
    }
  }
}
.cart-batch__modal {
  :deep(.sui-dialog__title-wrap) {
    /* stylelint-disable-next-line declaration-no-important */
    padding: 0 83px!important;
    background: #f4f4f4;
  }
  :deep(.sui-dialog__body::-webkit-scrollbar) {
    display: none;
  }
  :deep(.sui-dialog__body) {
    background: #f4f4f4;
    position: relative;
    overflow-x: hidden;
    padding: 0 83px;
  }
  :deep(.sui-dialog__title){
    /* stylelint-disable-next-line declaration-no-important */
    margin-bottom: 0!important;
  }
  .cart-batch__footer {
    position: fixed;
    width: 100%;
    height: 68px;
    bottom: 0;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 83px;
    border-top: 1px solid #E5E5E5;
    z-index: @zindex-hack;
    left: 0;
    >div:first-child {
      padding-left: 16px;
    }
  }
  .cart-batch__closeBtn {
    position: absolute;
    top: 22px;
    right: 21px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
    &:hover,&:focus{
      text-decoration: none;
    }
  }
  .cart-batch__operation-item {
    margin-left: 24px;
    font-weight: bold;
  }
  .cart-batch__item {
    background-color: #fff;
    padding: 8px 16px;
  }
}
</style>
